import { GqlQueryArgument, GqlQueryPart } from "src/app/services/graphql.module/model";
import { CreateGqlQueryArguments } from "../create.query";

export const AverageAskingPricesByListingTypeAndLocalityIdQuery = `
  averageAskingPrices(localityId:$localityId, listingType:$listingType){
    date,
    price,
    priceFormatted,
    abbreviatedMonth
  }
`;
export const AverageAskingPricesByListingTypeAndLocalityIdQueryArgs: Array<GqlQueryArgument> = [
  { variableName: "$localityId", variableType: "Int" },
  { variableName: "$listingType", variableType: "String!" }
]
export function CreateAverageAskingPricesByListingTypeAndLocalityIdQueryPart(localityId: number, listingType: string): GqlQueryPart {
  var result: GqlQueryPart = {
    queryConstant: AverageAskingPricesByListingTypeAndLocalityIdQuery,
    arguments: CreateGqlQueryArguments(AverageAskingPricesByListingTypeAndLocalityIdQueryArgs, [localityId, listingType])
  };
  return result;
};