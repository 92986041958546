
export const SaveSearchMutation = `
    mutation saveSearch($input: placeSearchCriteriaInput!) {
        savedSearchResult(placeSearchCriteriaInput: $input) {
            savedSearch {
                longitude,
                latitude,
                placeSearchCriteriaId,
                searchArea,
                locationName,
                radius,
                path,
                minPrice,
                maxPrice,
                placeTypeId,
                minNumberOfBedroomsId,
                maxNumberOfBedroomsId,
                sortType,
                listed,
                searchTypeId,
                hasThisAreaOnlyRadius,
                description,
                hasAlerts,
                hasRecommendations,
                page
            },
            errors{source,message}
        }
    }
`;
