
export const DeleteSavedSearchMutation = `
    mutation deleteSavedSearch($input: Int) {
        deleteSavedSearch(placeSearchCriteriaId: $input){
            savedSearch {
                placeSearchCriteriaId
            }
        }
    }
`;
