import { GqlQueryArgument, GqlQueryPart } from "src/app/services/graphql.module/model";
import { PlaceSearchCriteria } from "../../infrastructure/model/placeSearchCriteria";
import { CreateGqlQueryArguments } from "../create.query";

export const PropertyListingsQuery = `
  propertyListings(placeSearchCriteriaInput: $placeSearchCriteriaInput){
    adsTargeting{
      pageTypes,
      priceRanges,
      administrativeAreas,
      localities,
      postalCodesPartial,
      postalCodesFull,
      propertyTypes,
      propertySubTypes,
      numbersOfBeds
    },
    heading,
    filterText,
    httpStatusCode,
    breadcrumbList {key, value},
	  breadcrumbListJsonLd,
    placeSearchCriteria {
      longitude,
      latitude,
      placeSearchCriteriaId,
      searchArea,
      locationName,
      radius,
      path,
      minPrice,
      maxPrice,
      placeTypeId,
      minNumberOfBedroomsId,
      maxNumberOfBedroomsId,
      sortType,
      listed,
      searchTypeId,
      hasThisAreaOnlyRadius,
      description,
      hasAlerts,
      hasRecommendations,
      page,
      localityId,
      shareUrl,
      type,
      localityRadius,
      unroundedRadius
    },
    exactMatchCount,
    extendedMatchCount,
    pagination{
      pageFirstItemNumber,
      pageLastItemNumber,
      currentPage,
      total,
      items{value, url, isEnabled, isSelected, pageNumber, rel}
    },
    relatedSearches{key, value},
    robots{
      metaTitle,
      metaDescription,
      metaRobots,
      metaLanguage,
      canonicalUrl,
      next,
      previous
    },
    listings{
      placeId,
      photos,
      photosTotal,
      isNew,
      isSponsored,
      campaignId,
      tag,
      tagBackgroundColor,
      price,
      priceModifier,
      weeklyPrice,
      firstPublishedDate,
      features {title, description,iconUrl,badgeCount},
      title,
      subTitle,
      marketingInfo,
      detailsUrl,
      isRecommendation,
      latitude,
      longitude,
      address,
      countryCode,
      description,
      photosAlt,
      recommendationDistance,
      eCommerceTracking{
        id,
        name,
        brand,
        dimension1,
        category,
        coupon,
        variant,
        price,
        event,
        affiliation
      },
      formattedPrice,
      listingAdType,
      tags,
      listerLogoUrl
    },
    neighbourhoods {key,value},
    nearbyLocalities {key,value},
    reverseListingTypeLink{
      value,
      rel,
      url,
      isEnabled,
      title
    },
    areaGuide{
      averageScore,
      relativePath,
      linkRel,
      atAGlance
    },
    url
  }
`;
export const PropertyListingsQueryArgs: Array<GqlQueryArgument> = [{
  variableName: "$placeSearchCriteriaInput",
  variableType: "placeSearchCriteriaInput",
}];
export function CreatePropertyListingsQueryPart(placeSearchCriteria: PlaceSearchCriteria): GqlQueryPart {
  var result: GqlQueryPart = {
    queryConstant: PropertyListingsQuery,
    arguments: CreateGqlQueryArguments(PropertyListingsQueryArgs, [placeSearchCriteria])
  };
  return result;
};