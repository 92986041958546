import { Injectable } from "@angular/core";
import { PlaceSearchCriteria, LocallySavedPlaceSearchCriteria } from "src/app/infrastructure/model/placeSearchCriteria";
import { CopyObject, DeleteProperties } from "src/app/infrastructure/object.helpers";
import { BROWSER_STORAGE_KEYS } from "src/app/infrastructure/storage.keys";
import { DeleteSavedSearchMutation } from "src/app/graphql/mutations/DeleteSavedSearchMutation";
import { UpdateSearchRecommendationsMutation } from "src/app/graphql/mutations/UpdateSearchRecommendationsMutation";
import { UpdateSearchAlertsMutation } from "src/app/graphql/mutations/UpdateSearchAlertsMutation";
import { SaveSearchMutation } from "src/app/graphql/mutations/SaveSearchMutation";
import { MESSAGE_TYPES } from "src/app/infrastructure/message.types";
import { ShowInAppToastMessageData } from "src/app/infrastructure/message.data.types";
import { PropertyListingsResult } from "./model/propertyListingsResult";
import { GqlQueryBuilder } from "../graphql.module/graphql.query.builder";
import { QUERY_HYDRATION_KEYS } from "src/app/infrastructure/query.hydration.keys";
import { AverageAskingPriceListingResult } from "./model/averageAskingPrice";
import { PlaceSearchResult } from "./model/geo";
import { GraphQLService } from "../graphql.module/graphql.service";
import { AuthorizationService } from "../authorization.module/authorization.service";
import { BrowserStorageService } from "../browser.storage.module/browser.storage.service";
import { ApplicationStateService } from "../application.state.module/application.state.service";
import { Messenger } from "../messenger.module/messenger";
import { AverageAskingPricesByListingTypeAndLocalityIdQuery, AverageAskingPricesByListingTypeAndLocalityIdQueryArgs } from "src/app/graphql/queries/average.asking.prices.by.listing.type.and.locality.id.query";
import { CreateGqlQueryArguments } from "src/app/graphql/create.query";
import { CreatePropertyListingsQueryPart } from "src/app/graphql/queries/property.listings.query";


@Injectable({providedIn: 'root'})
export class PlacebuzzSearchService  {
    constructor(
        private gqlService: GraphQLService,
        private authorizationService: AuthorizationService,
        private browserStorageService: BrowserStorageService,
        private applicationStateService: ApplicationStateService,
        private messenger: Messenger
        
    ) { }


    previousSearchArea: string;


    protected searchCriteria: PlaceSearchCriteria = { searchTypeId: 2 };

    PerformPlacebuzzSearch(placesSearchResult: PlaceSearchResult, searchTypeId: number): Promise<PropertyListingsResult> {
      this.InsertPlaceIntoSearchCriteria(placesSearchResult);
      this.searchCriteria.searchTypeId = searchTypeId;  
      
      return new Promise(resolve => {
          this.PerformPropertyListingsSearch(
              this.searchCriteria,
              () => this.PerformPlacebuzzSearch(placesSearchResult, searchTypeId),
              this).then(results => {
                  resolve(results);
              });
      });
    }
  
  
    PerformPlacebuzzFullSearch(placesSearchResult: PlaceSearchResult,placeSearchCriteria: PlaceSearchCriteria): Promise<PropertyListingsResult> {
      this.searchCriteria = placeSearchCriteria;
      this.InsertPlaceIntoSearchCriteria(placesSearchResult);
      
      return new Promise(resolve => {
          this.PerformPropertyListingsSearch(this.searchCriteria, () => this.PerformPlacebuzzFullSearch(placesSearchResult, placeSearchCriteria),this).then(results => {
                  resolve(results);
          });
      });
    }
  
    private InsertPlaceIntoSearchCriteria(placesSearchResult: PlaceSearchResult): void {
        this.searchCriteria.administrativeAreaPathSegment = null;
        this.searchCriteria.localityPathSegment = null;
        this.searchCriteria.subLocalityPathSegment = null;
        this.searchCriteria.latitude = placesSearchResult.geoSearchResult.latitude;
        this.searchCriteria.longitude = placesSearchResult.geoSearchResult.longitude;
        this.searchCriteria.locationName = placesSearchResult.geoSearchResult.locationName;
        this.searchCriteria.searchArea = placesSearchResult.geoSearchResult.searchArea;
        this.searchCriteria.type = placesSearchResult.geoSearchResult.type;
        this.searchCriteria.localityId = placesSearchResult.geoSearchResult.localityId;
    }



    PerformPropertyListingsSearch(placeSearchCriteria: PlaceSearchCriteria, onRetry: Function, onRetryContext: any, mapSearch?:boolean): Promise<PropertyListingsResult> {
        return new Promise(resolve => {
            var isMutation = false;
            if (this.authorizationService.isLoggedIn && placeSearchCriteria) {
                isMutation = (placeSearchCriteria.saveSearch && placeSearchCriteria.saveSearch === true);  //|| this.HasPlaceSearchCriteriaId(placeSearchCriteria);
            }
            placeSearchCriteria = this.DeletePlaceSearchCriteriaProperties(placeSearchCriteria);
            
            if (mapSearch) {
                placeSearchCriteria.numberOfResults = 100;
            }
            if (this.applicationStateService.GetShouldResetRadius()) { 
                placeSearchCriteria.radius = null; placeSearchCriteria.page = null; 
            }
            
            if (!isMutation) {
                var forceNoSave = false;
                if (placeSearchCriteria.placeSearchCriteriaId && placeSearchCriteria.placeSearchCriteriaId > 0) {
                    forceNoSave = true;
                }

                this.DoPerformPropertyListingsSearch(placeSearchCriteria, onRetry, onRetryContext, forceNoSave).then(r => resolve(r));
            }
            else {
                this.PerformSaveSearchMutation(placeSearchCriteria, onRetry, onRetryContext).then(mutatedPlaceSearchCriteria => {
                    if (!this.HasPlaceSearchCriteriaId(placeSearchCriteria)) {
                        this.RemoveSearchFromLocalSearches(placeSearchCriteria);
                    }
                    placeSearchCriteria.placeSearchCriteriaId = mutatedPlaceSearchCriteria.placeSearchCriteriaId;
                    placeSearchCriteria.hasAlerts = mutatedPlaceSearchCriteria.hasAlerts;
                    this.DoPerformPropertyListingsSearch(placeSearchCriteria, onRetry, onRetryContext).then(r => resolve(r));
                });
            }
        });
    }

    PerformSaveSearchMutation(placeSearchCriteria: PlaceSearchCriteria, onRetry: Function, onRetryContext: any): Promise<PlaceSearchCriteria> {
        var searchCriteriaCopyForMutation = CopyObject(placeSearchCriteria);
        searchCriteriaCopyForMutation = DeleteProperties(searchCriteriaCopyForMutation, [
            "page",
            "localityId",
            "path",
            "description",
            "hasThisAreaOnlyRadius",
            "saveSearch",
            "heading",
            "badgeCount",
            "shareUrl",
            "localityRadius",
            "unroundedRadius"
        ]);

        return new Promise(resolve => {
            this.gqlService.ExecuteMutation(SaveSearchMutation, searchCriteriaCopyForMutation, onRetry, onRetryContext).then(response => {
                var result: any = {};
                if (this.gqlService.ProcessResponse(result, response)) {
                    if (!this.HasPlaceSearchCriteriaId(searchCriteriaCopyForMutation)) {
                        this.RemoveSearchFromLocalSearches(result.savedSearchResult.savedSearch);
                    }
                    this.browserStorageService.SetLocal(BROWSER_STORAGE_KEYS.LATEST_SEARCH, result.savedSearchResult.savedSearch);
                    this.messenger.Send({
                        messageType: MESSAGE_TYPES.SHOW_IN_APP_TOAST,
                        messageData: new ShowInAppToastMessageData("Search saved")
                    });
                    this.applicationStateService.RefreshMeFromServer();
                    resolve(result.savedSearchResult.savedSearch);
                }
             });
        });
    }

    PerformAveragePricesQuery(listingType: string, localityId: number, onRetry: Function, onRetryContext: any): Promise<AverageAskingPriceListingResult> {
        var queryBuilder = new GqlQueryBuilder(QUERY_HYDRATION_KEYS.AverageAskingPricesQuery);
        queryBuilder.BuildMultiPartQuery([{
            queryConstant: AverageAskingPricesByListingTypeAndLocalityIdQuery, arguments: CreateGqlQueryArguments(AverageAskingPricesByListingTypeAndLocalityIdQueryArgs, [localityId, listingType])
        }]);

        return new Promise(resolve => {
            this.gqlService.ExecuteQuery(QUERY_HYDRATION_KEYS.AverageAskingPricesQuery, queryBuilder.query, queryBuilder.variables, onRetry, onRetryContext, false, null, true).then(response => {
                var result: any = {};
                if (this.gqlService.ProcessResponse(result, response)) {
                    resolve(result);
                }
            });
        });
    }

    SetSavedSearchRecommendations(placeSearchCriteriaId: number, hasRecommendations: boolean, onRetry: Function, onRetryContext: any): Promise<boolean> {
        return new Promise(resolve => {
            this.gqlService.ExecuteMutation(UpdateSearchRecommendationsMutation, {
                placeSearchCriteriaId: placeSearchCriteriaId,
                hasRecommendations: hasRecommendations
            }, onRetry, onRetryContext).then(response => {
                var result: any = {};
                if (this.gqlService.ProcessResponse(result, response)) {
                    resolve(result.updateSavedSearchRecommendations);
                }
            });
        });
    }

    SetSavedSearchAlerts(placeSearchCriteriaId: number, hasAlerts: boolean, onRetry: Function, onRetryContext: any): Promise<boolean> {
        return new Promise(resolve => {
            this.gqlService.ExecuteMutation(UpdateSearchAlertsMutation, { placeSearchCriteriaId: placeSearchCriteriaId, hasAlerts: hasAlerts }, onRetry, onRetryContext).then(response => {
                var result: any = {};
                if (this.gqlService.ProcessResponse(result, response)) {
                    resolve(result.updateSavedSearchAlerts);
                }
            });
        });
    }

    GetLocalSearches(): Array<PlaceSearchCriteria> {
        var locallySavedSearches = this.browserStorageService.GetLocal(BROWSER_STORAGE_KEYS.LOCAL_SEARCHES);
        if (locallySavedSearches) {
            return locallySavedSearches;
        }
        return new Array<PlaceSearchCriteria>();
    }

    GetRemoteSearches(): Array<PlaceSearchCriteria> {
        var locallySavedSearches = this.browserStorageService.GetLocal(BROWSER_STORAGE_KEYS.REMOTE_SEARCHES);
        if (locallySavedSearches) {
            return locallySavedSearches;
        }
        return new Array<PlaceSearchCriteria>();
    }

    GetLatestSearch(): PlaceSearchCriteria {
        var result = this.browserStorageService.GetLocal(BROWSER_STORAGE_KEYS.LATEST_SEARCH) as PlaceSearchCriteria;
        return result;
    }

    SaveSearchLocally(searchCriteria: PlaceSearchCriteria, heading: string) {
        var isLocal = !this.HasPlaceSearchCriteriaId(searchCriteria);

        if (searchCriteria.type=='CentrePoint'){
            return;
        }

        var savedSearches = isLocal ? this.GetLocalSearches() : this.GetRemoteSearches();
        var copy = JSON.parse(JSON.stringify(searchCriteria));
        delete copy.page;
        copy.path = copy.path.replace(/Page=([^&#]*)/i, "");

        var searchToRemove: PlaceSearchCriteria;

        if (!isLocal) {
            // Server side saved search
            searchToRemove = savedSearches.find(s => s.placeSearchCriteriaId === searchCriteria.placeSearchCriteriaId);
        }
        else {
            // Local search
            searchToRemove = savedSearches.find(s => s.latitude === searchCriteria.latitude && s.longitude === searchCriteria.longitude && s.searchTypeId === searchCriteria.searchTypeId);
        }

        if (searchToRemove) {
            var savedSearchToUpdateIndex = savedSearchToUpdateIndex = savedSearches.indexOf(searchToRemove);
            savedSearches.splice(savedSearchToUpdateIndex, 1);
        }

        (copy as LocallySavedPlaceSearchCriteria).heading = heading;
        if (savedSearches.length > 0) {
            savedSearches.splice(0, 0, copy);
        }
        else {
            savedSearches.push(copy);
        }

        this.browserStorageService.SetLocal(isLocal ? BROWSER_STORAGE_KEYS.LOCAL_SEARCHES : BROWSER_STORAGE_KEYS.REMOTE_SEARCHES, savedSearches);

        // Save this search as latest
        this.browserStorageService.SetLocal(BROWSER_STORAGE_KEYS.LATEST_SEARCH, copy);
        if (this.authorizationService.isLoggedIn && !this.applicationStateService.me) {
            this.RefreshMeFromServer();
        }
    }

    DeleteLocalSearch(searchCriteria: PlaceSearchCriteria): void {
        var localSearches = this.GetLocalSearches();
        var searchToRemove = localSearches.find(s => s.latitude === searchCriteria.latitude && s.longitude === searchCriteria.longitude && s.searchTypeId === searchCriteria.searchTypeId);

        if (searchToRemove) {
            localSearches.splice(localSearches.indexOf(searchToRemove), 1);
            this.browserStorageService.SetLocal(BROWSER_STORAGE_KEYS.LOCAL_SEARCHES, localSearches);
            var latestSearch = this.browserStorageService.GetLocal(BROWSER_STORAGE_KEYS.LATEST_SEARCH) as PlaceSearchCriteria;
            if (latestSearch.latitude === searchCriteria.latitude && latestSearch.longitude === searchCriteria.longitude) {
                latestSearch = null;
                if (localSearches && localSearches.length > 0) {
                    latestSearch = localSearches[0];
                }
                this.browserStorageService.SetLocal(BROWSER_STORAGE_KEYS.LATEST_SEARCH, latestSearch);
            }
            this.applicationStateService.SendSavedSearchesUpdatedMessage();
        }
    }

    DeleteAllLocalSearches(): void {
        var localSearches = this.GetLocalSearches();
        if (localSearches && localSearches.length > 0) {
            for (var i = 0; i < localSearches.length; i++) {
                this.ClearIfLatest(localSearches[i]);
            }
        }
        this.browserStorageService.RemoveLocal(BROWSER_STORAGE_KEYS.LOCAL_SEARCHES);
        this.ClearLatestIfNoLists();
        this.applicationStateService.SendSavedSearchesUpdatedMessage();
    }

    DeleteSavedSearch(searchCriteria: PlaceSearchCriteria): Promise<void> {
        return new Promise(resolve => {
            this.gqlService.ExecuteMutation(DeleteSavedSearchMutation, searchCriteria.placeSearchCriteriaId, this.DeleteSavedSearch, this).then(response => {
                var result = {};
                if (this.gqlService.ProcessResponse(result, response)) {
                    this.ClearIfLatest(searchCriteria);
                    this.messenger.Send({
                        messageType: MESSAGE_TYPES.SHOW_IN_APP_TOAST,
                        messageData: new ShowInAppToastMessageData("Search deleted")
                    });
                    this.RefreshMeFromServer();
                    resolve();
                }
            });
        });
    }

    SaveLatestSearchToServer(): Promise<void> {
        return new Promise(resolve => {
            var latestSearch = this.GetLatestSearch();
            if (latestSearch) {
                this.PerformSaveSearchMutation(latestSearch, null, null).then(() => {
                    resolve();
                });
            }
        });
    }

    private DeletePlaceSearchCriteriaProperties(placeSearchCriteria: PlaceSearchCriteria) {
        return DeleteProperties(placeSearchCriteria, [
            "path",
            "hasThisAreaOnlyRadius",
            "description",
            "saveSearch",
            "badgeCount",
            "shareUrl",
            "localityRadius",
            "unroundedRadius"
        ]);
    }

    private RefreshMeFromServer() {
        this.applicationStateService.RefreshMeFromServer();
    }

    private HasPlaceSearchCriteriaId(placeSearchCriteria: PlaceSearchCriteria): boolean {
        return placeSearchCriteria.placeSearchCriteriaId !== null && placeSearchCriteria.placeSearchCriteriaId !== undefined && placeSearchCriteria.placeSearchCriteriaId !== 0;
    }

    private RemoveSearchFromLocalSearches(searchCriteria: PlaceSearchCriteria) {
        var savedSearches = this.GetLocalSearches();
        var searchToRemove = savedSearches.find(s => s.latitude === searchCriteria.latitude && s.longitude === searchCriteria.longitude);
        if (!searchToRemove) {
            searchToRemove = savedSearches.find(s => s.administrativeAreaPathSegment === searchCriteria.administrativeAreaPathSegment && s.localityPathSegment === searchCriteria.localityPathSegment);
        }
        if (searchToRemove && searchToRemove.searchTypeId === searchCriteria.searchTypeId) {
            var savedSearchToUpdateIndex = savedSearchToUpdateIndex = savedSearches.indexOf(searchToRemove);
            savedSearches.splice(savedSearchToUpdateIndex, 1);
        }
        this.browserStorageService.SetLocal(BROWSER_STORAGE_KEYS.LOCAL_SEARCHES, savedSearches);
        this.ClearLatestIfNoLists();
    }

    private DoPerformPropertyListingsSearch(placeSearchCriteria: PlaceSearchCriteria, onRetry: Function, onRetryContext: any, forceNoSave?: boolean): Promise<PropertyListingsResult> {
        
        return new Promise(resolve => {
           
            var queryBuilder = new GqlQueryBuilder(QUERY_HYDRATION_KEYS.PropertyListingsQuery);
            queryBuilder.BuildMultiPartQuery([
                CreatePropertyListingsQueryPart(placeSearchCriteria)
            ]);
            this.gqlService.ExecuteQuery(QUERY_HYDRATION_KEYS.PropertyListingsQuery, queryBuilder.query, queryBuilder.variables, onRetry, onRetryContext).then(response => {
                var result: any = {};
                if (this.gqlService.ProcessResponse(result, response)) {
                    if (!result.propertyListings) { return; }
                    if (!forceNoSave) {
                        this.SaveSearchLocally(result.propertyListings.placeSearchCriteria, result.propertyListings.heading);
                    }
                    resolve(result.propertyListings);
                }
            });
        });
    }

    private ClearLatestIfNoLists() {
        var remoteSearches = this.GetRemoteSearches();
        var localSearches = this.GetLocalSearches();
        if ((!remoteSearches || remoteSearches.length === 0) && (!localSearches || localSearches.length === 0)) {
            this.browserStorageService.RemoveLocal(BROWSER_STORAGE_KEYS.LATEST_SEARCH);
        }
    }

    private ClearIfLatest(search: PlaceSearchCriteria) {
        var latest = this.GetLatestSearch();

        if (latest && latest.latitude === search.latitude && latest.longitude === search.longitude && latest.searchTypeId === search.searchTypeId) {
            this.browserStorageService.RemoveLocal(BROWSER_STORAGE_KEYS.LATEST_SEARCH);
        }
    }
}